import React, { useEffect, useState } from 'react';
import { Carousel, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function Slider() {

  const [banner, setBanner] = useState(null);
  const [isChristmasBanner, setIsChristmasBanner] = useState(false);
  const [isNewYearBanner, setIsNewYearBanner] = useState(false);

  useEffect(() => {
    const auth = JSON.parse(JSON.parse(localStorage.getItem('persist:american-burrs')).parceiro).cnpjRevenda;
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const fetchUser = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/revendas/cnpj/${auth}`, {
          method: 'GET',
        });
        const data = await response.json();

        const currentDate = new Date();
        const startDateChristmas = new Date(currentDate.getFullYear(), 11, 9); // 9 de dezembro
        const endDateChristmas = new Date(currentDate.getFullYear(), 11, 26); // 26 de dezembro
        const startDateNewYear = new Date(currentDate.getFullYear(), 11, 26); // 26 de dezembro
        const endDateNewYear = new Date(currentDate.getFullYear() + 1, 0, 9); // 9 de janeiro

        if (currentDate >= startDateChristmas && currentDate <= endDateChristmas) {
          setBanner("imagens/Banner-Natal-09.12-a-26.12.png");
          setIsChristmasBanner(true);
          setIsNewYearBanner(false);
        } else if (currentDate >= startDateNewYear && currentDate <= endDateNewYear) {
          setBanner("imagens/Banner-Ano-Novo-27.12-a-09.01.png");
          setIsNewYearBanner(true);
          setIsChristmasBanner(false);
        } else {
          setBanner("imagens/banner-alexa-web.jpg");
          setIsChristmasBanner(false);
          setIsNewYearBanner(false);
        }
      } catch (error) {
        console.error('Erro ao buscar banner:', error);
      }
    };

    fetchUser();
  }, []);

  const handlePixBannerClick = (e) => {
    e.preventDefault();
    setTimeout(() => {
      window.scrollTo({
        top: 580,
        behavior: 'smooth'
      });
    }, 100);
  };

  return (
    <Container fluid="md" className="mb-5">
      <Row>
        <div className="d-none d-sm-block">
          <Carousel interval={3000}>
            <Carousel.Item>
              <div className="d-none d-md-flex">
                <Link to="/loja" onClick={handlePixBannerClick}>
                  <img
                    className="d-block w-100"
                    src="imagens/banner-pix-web.jpg"
                    alt="Slider"
                  />
                </Link>
              </div>
              <div className="d-flex d-md-none">
                <Link to="/loja" onClick={handlePixBannerClick}>
                  <img
                    className="d-block w-100"
                    src="imagens/banner-pix-mobile.jpg"
                    alt="Slider"
                  />
                </Link>
              </div>
            </Carousel.Item>
            {isChristmasBanner ? (
              <Carousel.Item>
                <div className="d-none d-md-flex">
                  <Link to="" onClick={(e) => e.preventDefault()}>
                    <img
                      className="d-block w-100"
                      src={banner}
                      alt="Slider"
                    />
                  </Link>
                </div>
                <div className="d-flex d-md-none">
                  <Link to="" onClick={(e) => e.preventDefault()}>
                    <img
                      className="d-block w-100"
                      src={banner}
                      alt="Slider"
                    />
                  </Link>
                </div>
              </Carousel.Item>
            ) : isNewYearBanner ? (
              <Carousel.Item>
                <div className="d-none d-md-flex">
                  <Link to="" onClick={(e) => e.preventDefault()}>
                    <img
                      className="d-block w-100"
                      src={banner}
                      alt="Slider"
                    />
                  </Link>
                </div>
                <div className="d-flex d-md-none">
                  <Link to="" onClick={(e) => e.preventDefault()}>
                    <img
                      className="d-block w-100"
                      src={banner}
                      alt="Slider"
                    />
                  </Link>
                </div>
              </Carousel.Item>
            ) : (
              <Carousel.Item>
                <div className="d-none d-md-flex">
                  <Link to="/premio/10123">
                    <img
                      className="d-block w-100"
                      src="imagens/banner-alexa-web.jpg"
                      alt="Slider"
                    />
                  </Link>
                </div>
                <div className="d-flex d-md-none">
                  <Link to="/premio/10123">
                    <img
                      className="d-block w-100"
                      src="imagens/banner-alexa-mobile.jpg"
                      alt="Slider"
                    />
                  </Link>
                </div>
              </Carousel.Item>
            )}
          </Carousel>
        </div>
        <div className="d-sm-none">
          <Carousel indicators={false} interval={3000}>
            <Carousel.Item>
              <div className="d-none d-md-flex">
                <Link to="/loja" onClick={handlePixBannerClick}>
                  <img
                    className="d-block w-100"
                    src="imagens/banner-pix-mobile.jpg"
                    alt="Slider"
                  />
                </Link>
              </div>
              <div className="d-flex d-md-none">
                <Link to="/loja" onClick={handlePixBannerClick}>
                  <img
                    className="d-block w-100"
                    src="imagens/banner-pix-mobile.png"
                    alt="Slider"
                  />
                </Link>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="d-none d-md-flex">
              <Link to="/premio/10123">
                  <img
                    className="d-block w-100"
                    src="imagens/banner-alexa-web.jpg"
                    alt="Slider"
                  />
                </Link>
              </div>
              <div className="d-flex d-md-none">
              <Link to="/premio/10123">
                  <img
                    className="d-block w-100"
                    src="imagens/banner-alexa-mobile.jpg"
                    alt="Slider"
                  />
                </Link>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </Row>
    </Container>
  );
}
